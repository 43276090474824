import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'
import styled from 'styled-components'

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: #faf9f6;
`
const Title = styled.h2`
  @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville&display=swap');
  font-family: 'Libre Baskerville', serif;
  font-size: 28px;
  color: black;
  text-align: center;
  padding: 50px 0;
  margin: 0 auto;
  font-weight: 600;
`
const Section = styled.section`
  background-color: #faf9f6;
  padding: 0 100px 50px 100px;
  @media screen and (max-width: 543px) {
    padding: 0 20px 50px 20px;
}
`
const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: #faf9f6;
`
const Content = styled.div`
`

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Header>
          <Title>BLOG</Title>
        </Header>
        <Section>
          <Container>
            <Content className="content">
              <BlogRoll />
            </Content>
          </Container>
        </Section>
      </Layout>
    )
  }
}
